<template>
  <v-menu bottom rounded min-width="200px">
    <template v-slot:activator="{ on }">
      <v-btn icon x-large v-on="on" class="z-imgBorder">
        <v-avatar color="tertiary" size="48">
          <!--              <span class="white--text text-h5">{{ user.initials }}</span>-->
          <v-img :src="get_image()"></v-img>
        </v-avatar>
      </v-btn>
    </template>
    <v-card>
      <v-list-item-content class="justify-center">
        <div class="mx-auto text-center">
          <v-avatar color="tertiary">
            <v-img :src="get_image()"></v-img>
          </v-avatar>
          <h3 class="mt-2">{{ $store.state.user.name }}</h3>
          <p class="text-caption mt-1">
            {{ $store.state.user.email }}
          </p>
          <v-divider class="my-3"></v-divider>
          <v-btn depressed rounded text @click="$router.push('/core-user-profile')">
            Профиль
          </v-btn>
          <v-divider class="my-3"></v-divider>
          <v-btn depressed rounded text @click="logout()">
            Выход
          </v-btn>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>



export default {
  name: "v.avatar",
  data() {
    return {
      user: {
        initials: 'JD',
        fullName: 'John Doe',
        email: 'john.doe@doe.com',
        img: 'https://cdn.vuetifyjs.com/images/john.png',
      },
    }
  },
  methods: {
    logout() {
      this.$axios.post(this.$url.rest_logout).then(response => {
        response
        localStorage.removeItem('access_token')
        this.$store.state.token = null
        this.$router.push('/login')
      })
    },
    get_image() {
      if (this.$store.state.user.avatar)
        return this.$store.state.develop ? this.$store.state.domen + this.$store.state.user.avatar : this.$store.state.user.avatar
      else
        return require('@/assets/avatar.png')
    }
  },
}
</script>

<style>

.z-imgBorder {
  border: 3px solid #b3dbf4 !important;
  opacity: 0.7;
}

</style>